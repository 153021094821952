import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return (
      state.userProfile && state.userProfile.is_superuser && state.userProfile.is_active
    );
  },
  loginError: (state: MainState) => state.logInError,
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  userProfile: (state: MainState) => state.userProfile,
  userDevice: (state: MainState) => state.userDevice,
  userLocation: (state: MainState) => state.userLocation,
  battery: (state: MainState) => state.battery,
  plan: (state: MainState) => state.plan,
  token: (state: MainState) => state.token,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
};

const { read } = getStoreAccessors<MainState, State>("");

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readUserDevice = read(getters.userDevice);
export const readUserLocation = read(getters.userLocation);
export const readBattery = read(getters.battery);
export const readPlan = read(getters.plan);
export const readFirstNotification = read(getters.firstNotification);
