import axios from "axios";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IBattery,
  IPlan,
  IUserSignup,
  IUserSignupAddress,
  IUserSignupDevice,
} from "./interfaces";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async register(data: IUserSignup) {
    return axios.post(`${apiUrl}/api/v1/signup/signup`, data);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async getMeDevice(token: string) {
    return axios.get<IUserSignupDevice>(
      `${apiUrl}/api/v1/device/get-my-devices`,
      authHeaders(token),
    );
  },
  async getLocation(token: string) {
    return axios.get<IUserSignupAddress>(
      `${apiUrl}/api/v1/location/get-full-user-location-info`,
      authHeaders(token),
    );
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token),
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getMyBatteries(user_id: number, token: string) {
    return axios.get<IBattery>(
      `${apiUrl}/api/v1/charger/get-user-chargers/${user_id}`,
      authHeaders(token),
    );
  },

  async getMyPlan(serial_number: string, token: string) {
    return axios.get<IPlan>(
      `${apiUrl}/api/v1/planner/${serial_number}`,
      authHeaders(token),
    );
  },

  async createBattery(token: string, data: IBattery) {
    return axios.post(`${apiUrl}/api/v1/charger/`, data, authHeaders(token));
  },
};
