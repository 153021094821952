import {
  IBattery,
  IPlan,
  IUserProfile,
  IUserSignupAddress,
  IUserSignupDevice,
} from "@/interfaces";
import { MainState, AppNotification } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setUserProfile(state: MainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setUserDevice(state: MainState, payload: IUserSignupDevice) {
    state.userDevice = payload;
  },
  setUserLocation(state: MainState, payload: IUserSignupAddress) {
    state.userLocation = payload;
  },
  setBattery(state: MainState, payload: IBattery) {
    state.battery = payload;
  },
  setPlan(state: MainState, payload: IPlan) {
    state.plan = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload,
    );
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitSetUserDevice = commit(mutations.setUserDevice);
export const commitSetUserLocation = commit(mutations.setUserLocation);
export const commitSetBattery = commit(mutations.setBattery);
export const commitSetPlan = commit(mutations.setPlan);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
